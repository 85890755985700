import * as React from "react";

import { cn } from "../utils/tailwind";
import { ToggleGroup, ToggleGroupItem } from "./toggle-group";

interface FilterOptions {
  label: string;
  value: string;
  color?: string;
  dashed?: boolean;
}

interface FilterProps {
  options: FilterOptions[];
}

export const Filter = React.forwardRef<
  React.ElementRef<typeof ToggleGroup>,
  React.ComponentPropsWithoutRef<typeof ToggleGroup> & FilterProps
>(({ size = "sm", className, options, ...props }, ref) => {
  return (
    <ToggleGroup
      ref={ref}
      size={size}
      className={cn("min-h-9 rounded-md border px-1.5", className)}
      {...props}
    >
      {options.map((option) => (
        <ToggleGroupItem
          className="text-nowrap"
          key={option.value}
          value={option.value}
          color={option.color}
          dashed={option.dashed}
          aria-label={`Toggle ${option.label}`}
        >
          {option.label}
        </ToggleGroupItem>
      ))}
    </ToggleGroup>
  );
});
