import { Protect } from "@clerk/nextjs";
import {
  NavigationMenu,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@winclap-platform/ui/components/navigation-menu";
import Link from "next/link";
import { useRouter } from "next/router";

const NavigationLink = ({ href, label }: { href: string; label: string }) => {
  const router = useRouter();
  return (
    <Link href={href} legacyBehavior passHref>
      <NavigationMenuLink
        className={navigationMenuTriggerStyle()}
        active={router.pathname === href}
      >
        {label}
      </NavigationMenuLink>
    </Link>
  );
};

export const ReportsNavigation = () => (
  <NavigationMenu className="mb-4">
    <NavigationMenuList>
      <Protect permission="org:reports:read">
        <NavigationLink href="/reporting" label="Report history" />
      </Protect>
      <Protect permission="org:report_schedule:list">
        <NavigationLink href="/reporting/schedule" label="Report schedule" />
      </Protect>
      <Protect permission="org:reports:create">
        <NavigationLink href="/reporting/create" label="Generate report" />
      </Protect>
    </NavigationMenuList>
  </NavigationMenu>
);
